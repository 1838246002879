//New variables
var $ = jQuery;
var tocScroll = null;
var body = document.querySelector('body');
var themeSwitcher = document.querySelector('.js-theme-switcher');
var themeMode = JSON.parse(localStorage.getItem('themeMode')) || 'dark';
var input = document.getElementsByTagName('input');
var mousedownHappened = false;
let dropdownArror = `<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" x="0px" y="0px" viewBox="0 0 24 24" width="24px" height="24px" data-mds-component="ArrowDownIcon" class="arrow-dark" style="stroke: none;"><g><polygon points="12,13.2 6.7,7.9 5.3,9.4 12,16.1 18.7,9.4 17.3,7.9 "></polygon></g></svg>`;
let versionDropdownSelect = $('.version-dropdown-select');

if (versionDropdownSelect.length) {
  versionDropdownSelect.append(dropdownArror);
}

//Anchors smooth scrolling
$('a[href^="#"]').each(function() {
  $(this).click(function(e) {
    e.preventDefault();
    const target = $(this).attr('href');
    if (target.length) {
      const scrollTo = $(target).offset().top;
      $('body, html').animate({
        scrollTop: (scrollTo - 62) + 'px'
      }, 500);
    }
  });
})

//Fake focus on search input parent div
for (i=0; i<input.length; i++){
  // you can omit the 'if' if you want to style the parent node regardless of its
  // element type
  if (input[i].parentNode.tagName.toString().toLowerCase() == 'div') {
    input[i].onfocus = function(){
      this.parentNode.classList.add('focused');
    }
    input[i].onblur = function(){
      this.parentNode.classList.remove('focused');
    }
  }
}

//Theme mode setter and toggler
function themeModeToggler() {
  themeMode = (themeMode === 'dark') ? 'light' : 'dark';
  body.dataset.theme = themeMode;

  localStorage.setItem('themeMode', JSON.stringify(themeMode));
}

function setThemeModeByDefault() {
  var sessionThemeMode = JSON.parse(localStorage.getItem('themeMode'));
  body.dataset.theme = sessionThemeMode || themeMode;

  if (sessionThemeMode === 'light') {
    themeSwitcher.checked = false;
  }
}

//Highlight section in viewport
function setupScrollSpy() {
  if (tocScroll === null) {
    return;
  }

  // Scrollspy -- highlight table on contents, based on scroll
  var spy = new Gumshoe(".toc-tree a", {
    offset: 62,
    reflow: true,
    recursive: true,
    navClass: "scroll-current",
  });
}

////////////////////////////////////////////////////////////////////////////////
// Main entrypoint
////////////////////////////////////////////////////////////////////////////////
function main() {
  document.body.parentNode.classList.remove("no-js");
  tocScroll = document.querySelector(".toc-scroll");

  setupScrollSpy();
}

//Version switcher dropdown toggler
$('.version-dropdown-select').on('click', function() {
  $(this).parent().toggleClass('open');
})
$('.version-dropdown-select').on('blur', function() {
  if (mousedownHappened) {
    mousedownHappened = false
  } else {
    $(this).parent().removeClass('open')
  }
})
$('.version-dropdown a').mousedown(function() {
  mousedownHappened = true;
});

themeSwitcher.addEventListener("change", themeModeToggler);
window.onload = setThemeModeByDefault();

document.addEventListener("DOMContentLoaded", main);

////////////////////////////////////////////////////////////////////////////////
// GOOGLE SEARCH
////////////////////////////////////////////////////////////////////////////////
var gapi = window.gapi;

function googleCustomSearch() {
  if (!gapi) return;

  const APIKey = 'AIzaSyBx8GEsQOCDNsfflmXYwNmIuk9D0UFCkCU';
  const userId = 'ed373c33609ed608b';
  const defaultSiteSearch = 'https://docs.mirantis.com/';

  const searchForm = $('.js-form');
  const searchInput = $('.js-form-input');
  const searchFilters = $('.js-filters');
  const searchFilterItem = $('.js-search-filter');
  const searchLoader = $('.js-search-loader');
  const searchNoResults = $('.js-no-results');
  const searchError = $('.js-search-error');
  const searchNoResultsQuery = $('.no-results-query');
  const clearButton = $('.js-search-clear');
  const clearButtonResults = $('.js-search-clear-results');
  const asideToggler = $('#__navigation');

  let store = {
    loading: false,
    loaded: false,
    error: null
  };
  let searchTerm = '';
  let siteSearch = defaultSiteSearch;
  let data = [];

  function loadClient() {
    if (!gapi) {
      store.loading = false;
      store.loaded = true;
      store.error = 'Google API is unavailable.'; // eslint-disable-next-line no-console

      console.error('`gapi` is not loaded in global. Unable to search documentation.');
      return;
    } // start load


    store.loading = true;
    store.loaded = false;
    store.error = null;

    gapi.load('client', async function () {
      gapi.client.setApiKey(APIKey);
      gapi.client.load('https://content.googleapis.com/discovery/v1/apis/customsearch/v1/rest').then(function () {
        // if success
        store.loading = false;
        store.loaded = true;

        searchForm.removeClass('mod-disabled');
        searchInput.attr('disabled', false);
      }, function (err) {
        store.loading = false;
        store.loaded = true;
        store.error = 'Failed to load Google Search API.'; // eslint-disable-next-line no-console

        console.error('Error loading Google Search API. Unable to search documentation.', err);
      });
    });
  }

  // Make sure the client is loaded before calling this method.


  function execute(searchTerm, siteSearch) {
    showElement(searchLoader);

    if ($(window).width() < 1073) {
      asideToggler[0].checked = false;
    }

    return gapi.client.search.cse.list({
      cx: userId,
      siteSearch: siteSearch,
      siteSearchFilter: 'i',
      q: searchTerm
    }).then(function (response) {
      // Handle the results here (response.result has the parsed body).
      const {
        result: { items },
      } = response;
      data = items;

      hideElement(searchLoader);
      if (!data) {
        searchNoResultsQuery.html(searchTerm);
        showElement(searchNoResults);
        showElement(clearButtonResults);
      } else {
        hideElement(searchNoResults);
        showSearchResults(data);
        showElement(searchFilters);
        showElement(clearButtonResults);
      }
    }, function (err) {
      hideElement(searchLoader);
      hideElement(searchNoResults);
      showElement(searchError);
      showElement(clearButtonResults);
      console.error("Execute error", err);
    });
  }

  window.onload = function(){
    loadClient()
  }

  function onSearchFormSubmit(e) {
    e.preventDefault();
  };

  function showElement(element) {
    element.css({
      'opacity': 1,
      'visibility': 'visible'
    })
  }

  function hideElement(element) {
    element.css({
      'opacity': 0,
      'visibility': 'hidden'
    })
  }

  function onSearchTermChange(e) {
    if (!e.target.value) {
      clearData();
      hideElement(searchFilters);
      hideElement(searchNoResults);
      hideElement(searchError);
      hideElement(clearButton);
    } else {
      showElement(clearButton);
    }
    searchTerm = e.target.value;
  };

  function onSearchKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (searchTerm) {
        execute(searchTerm, siteSearch);
      }
    }
  };

  function clearData() {
    searchTerm = '';
    data = [];
    $('#google-search-results ul').remove();
  }

  function setSiteSearch(siteSearchValue) {
    siteSearch = siteSearchValue;
  }

  function showSearchResults(array) {
    // Create the list element:
    var list = document.createElement('ul');

    for (var i = 0; i < array.length; i++) {
        // Create the list item:
        var item = document.createElement('li');
        var itemTitle = document.createElement('a');
        var itemSubTitle = document.createElement('a');
        var itemSnippet = document.createElement('p');

        // Set its contents:
        itemTitle.appendChild(document.createTextNode(array[i].title));
        itemTitle.setAttribute('href', array[i].link);
        itemTitle.setAttribute('target', '_blank');

        itemSubTitle.appendChild(document.createTextNode(array[i].link));
        itemSubTitle.setAttribute('href', array[i].link);
        itemSubTitle.setAttribute('target', '_blank');
        itemSubTitle.setAttribute('class', 'search-result-helper-link');

        itemSnippet.appendChild(document.createTextNode(array[i].snippet));

        item.appendChild(itemTitle);
        item.appendChild(itemSubTitle);
        item.appendChild(itemSnippet);

        // Add it to the list:
        list.appendChild(item);
    }

    $('#google-search-results ul').remove();
    $('#google-search-results').append(list);
  }

  searchForm.on('submit', function(e) {
    onSearchFormSubmit(e);
  });

  searchInput.on('input', function(e) {
    onSearchTermChange(e);
  })

  searchInput.on('keydown', function(e) {
    onSearchKeyDown(e)
  })

  searchFilterItem.on('change', function(e) {
    setSiteSearch(e.target.value);
    execute(searchTerm, siteSearch);
  })

  clearButton.on('click', function() {
    clearData();
    hideElement(searchFilters);
    hideElement(searchNoResults);
    hideElement(searchError);
    hideElement(clearButton);
    hideElement(clearButtonResults);
    searchInput.val('');
  })

  clearButtonResults.on('click', function() {
    clearData();
    hideElement(searchFilters);
    hideElement(searchNoResults);
    hideElement(searchError);
    hideElement(clearButton);
    hideElement(clearButtonResults);
    searchInput.val('');
  })
}

googleCustomSearch();

// Copy to clipboard
function copyToClipboard(element) {
  let $temp = $("<input>");
  let currentUrl = window.location.href;

  $("body").append($temp);

  if ($(element).attr('href').match("^#")) {
    $temp.val(`${currentUrl}${$(element).attr('href')}`).select();
  } else {
    $temp.val($(element).attr('href')).select();
  }

  document.execCommand("copy");
  $temp.remove();
}

$('.headerlink').click(function(e) {
  let _this = this;

  e.preventDefault();
  copyToClipboard(this);
  $(this).addClass('copied');

  setTimeout(function(){
    $(_this).removeClass('copied');
  }, 1000);
})

// Indicate current button in single html buttons group
function indicateCurrentPageButton() {
  if (!('.html-buttons').length) return;

  let currentUrl = window.location.href;
  const singleSlug = '/single';
  const buttons = $('.html-buttons__item a');

  buttons.each(function() {
    $(this).removeClass('current');
    let buttonUrl = $(this).attr('href');

    if (currentUrl.includes(singleSlug) && buttonUrl.includes(singleSlug)) {
      $(this).addClass('current');
    } else if (!currentUrl.includes(singleSlug) && !buttonUrl.includes(singleSlug)) {
      $(this).addClass('current');
    }
  })
}

indicateCurrentPageButton();

function isSinglePage() {
  const singlePageIdentifier = '/single';
  const currentUrl = window.location.href;

  if (currentUrl.includes(singlePageIdentifier)) {
    return true;
  } else {
    return false;
  }
}

function removeTocTree() {
  const tocTreeTitle = $('.toc-title-container');
  const tocTreeContainer = $('.toc-tree-container');

  if (isSinglePage()) {
    tocTreeTitle.remove();
    tocTreeContainer.remove();
  }
}

removeTocTree();

function searchOnSinglePage() {
  const searchForm = $('.sidebar-search-container');

  if (!searchForm.length) return;

  const currentUrl = window.location.href;
  const singleSlug = '/single';
  const searchSlug = 'search.html';

  if (currentUrl.includes(singleSlug)) {
    const multiPageUrl = currentUrl.replace(singleSlug, '');
    const newSearchAction = `${multiPageUrl}${searchSlug}`;

    searchForm.attr('action', newSearchAction)
  }
}

searchOnSinglePage();